import Login from "./Login";

const App = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default App;
