import "./index.css";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setLoginError("Misslyckades med att logga in. Vänligen försök igen.");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full py-12 space-y-12 bg-white p-8 rounded-lg shadow-md">
        <div className="flex items-center justify-center gap-6">
          <img
            src={require("./img/diamond.png")}
            alt="Diamond"
            className="w-16"
          />
          <h1 className="text-center text-5xl font-bold">CartaGem</h1>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
        >
          <input type="hidden" name="remember" value="true" />
          <div className="flex gap-4">
            <div className="flex-1">
              <label htmlFor="username" className="sr-only">
                Username
              </label>
              <input
                {...register("username", { required: "Användarnamn krävs" })}
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder="Användarnamn"
              />
              {errors.username && (
                <p className="text-red-500 text-xs italic">
                  {errors.username.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                {...register("password", { required: "Lösenord krävs" })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
                placeholder="Lösenord"
              />
              {errors.password && (
                <p className="text-red-500 text-xs italic">
                  {errors.password.message}
                </p>
              )}
            </div>
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Logga in
            </button>
          </div>
          {loginError && (
            <div className="mt-4 border-2 text-center text-red-600 p-2 border-red-600">
              {loginError}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
